export const slurs = [
  "abo",
  "beaner",
  "chink",
  "coon",
  "cunt",
  "dago",
  "eskimo",
  "fag",
  "faggot",
  "fucktard",
  "gook",
  "goy",
  "jap",
  "kike",
  "kys",
  "libtard",
  "mong",
  "nigga",
  "nigger",
  "paki",
  "retard",
  "retarded",
  "schizo",
  "schizzo",
  "schizzy",
  "schizzie",
  "spastic",
  "sperg",
  "spic",
  "strag",
  "straggot",
  "tard",
  "trannie",
  "tranny",
  "troon",
  "wetback",
  "wog",
  "yid",
];
